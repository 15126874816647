import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { createUseStyles } from 'react-jss';

import Layout from './layouts/Layout';
import Home from './pages/Home';
import Photography from './pages/Photography';
import Publications from './pages/Publications';
import About from './pages/About';

const useStyles = createUseStyles({
	"@global": {
		a: {
			"&:link": {
				color: "coral"
			},
			"&:visited": {
				color: "coral"
			}
		}
	}
})

function App() {
	useStyles()
  return (
    <BrowserRouter>
		<Routes>
			<Route path="/" element={<Layout page={<Home />} />} />
			<Route path="/photography" element={<Layout page={<Photography />} />} />
			<Route path="/publications" element={<Layout page={<Publications />} />} />
			<Route path="/about" element={<Layout page={<About />} />} />
		</Routes>
	</BrowserRouter>
  );
}

export default App;
