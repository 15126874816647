import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	description: {
		maxWidth: 700
	}
})

export default function Journal() {
	const classes = useStyles()
	return (
		<div>
			<h3>Washington Square News</h3>
			<p className={classes.description}>Reading the morning newspaper as a child with my grandfather in Bangalore, India and observing cultural health practices around the world sparked my desire to tell stories. In high school, watching the news every night with my mom at dinner and developing a love for writing in English class pushed me to try journalism as a college first-year. The rest is history. I love covering what different communities face in captivating interviews along the lines of medicine, public health, social justice and travel. In my Editor roles, I’ve also helped manage staff and determine weekly content for our desks. My work has been picked up by the New York Times, ABC, NBC, and other media companies. Find all my published work for WSN on my <a href="https://nyunews.com/staff_name/roshni-raj/">Staff Bio. </a></p>
			<ul style={{listStyleType: "none"}}>
				<li>
					<i>Culture Editor, Spring 2022 - Present</i>
					<ul style={{listStyleType: "none"}}>
						<li>
							<a href="https://nyunews.com/abroad/culture/2022/05/06/lexie-alford-solo-travel-interview/">Q&A: Guinness World Record holder Lexie Alford on traveling to every country</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/2022/05/20/abu-dhabi-shanghai-seniors-commencement/">Abu Dhabi, Shanghai seniors come together at NYC commencement</a>
						</li>
						<li>
							<a href="https://nyunews.com/underthearch/specialissues/2022/04/08/ie-re-students-selling-stickers/">Re: ‘Healing our inner child’: Students Selling Stickers for The Identity & Equity Special Issue</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/dining/2022/03/04/best-bottomless-brunch-nyu-nyc/">The best places for bottomless brunch around NYU</a>
						</li>
					</ul>
				</li>

				<li>
					<i>Abroad Culture Editor, Fall 2021</i>
					<ul style={{listStyleType: "none"}}>
						<li>
							<a href="https://nyunews.com/news/2021/09/08/afghan-student-reflects-on-taliban-takeover/">‘I’m scared for my life’: An Afghan student on the collapse of Afghanistan</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/07/19/international-students-covid-vaccination/">Facing conflicting guidance, international students struggle with NYU COVID-19 vaccine requirements</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/08/04/international-students-nyu-vaccine-requirement/">International students may be required to ‘mix’ COVID-19 vaccines, despite NYU assurances</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/09/27/nyu-abu-dhabi-10-anniversary/">NYU Abu Dhabi celebrates its 10th anniversary</a>
						</li>
					</ul>
				</li>

				<li>
					<i>Deputy News Editor, Spring 2020 - Spring 2021</i>
					<ul style={{listStyleType: "none"}}>
						<li>
							<i>Medicine and Health</i>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/09/11/nyu-covid-case-positive-student-rubin/">Rubin Student Tests Positive for COVID-19 on Move-In Day</a>
						</li>
						<li>
							<a href="https://nyunews.com/2020/05/06/students-create-mutual-aid-network-to-help-those-affected-by-pandemic/">Students Create Mutual Aid Network to Help Those Affected by Pandemic</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/02/05/healthcare-disparities-racial-bias/">NYU Alumna Talks Racial and Ethnic Biases in US Healthcare System</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/04/02/international-nurses-workforce-stability/">Nurses Educated Abroad Create Workforce Stability, New Study Finds</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/09/21/nyu-langone-telemedicine-blueprint-covid19/">NYU Langone Develops Telemedicine Blueprint</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/01/29/nyu-langone-new-organ-transplant-technique/">Langone Transplant Institute Performs First Successful Heart Transplant in US Using New Method</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/03/15/in-person-classes-activities-pandemic/">How in-person experiences continue despite the pandemic</a>
						</li>
						<li>
							<i>Social Justice and Travel</i>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/05/03/professors-solidarity-gsoc-strike/">Professors express solidarity with GSOC</a>
						</li>
						<li>
							<a href="https://nyunews.com/2021/03/22/rallies-and-protests-against-anti-asian-violence-held-in-nyc/">Rallies in New York City call attention to anti-Asian violence</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/02/26/un-afghanistan-ambassador-visits-nyu/">UN Ambassador to Afghanistan Visits NYU Prior to Peace Talks</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/09/02/nyu-dentistry-racism-task-force/">NYU Dentistry Creates Task Force After Students Raise Concerns</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/07/28/mlk-scholars-frustrated-tokenism-performative-activism/">MLK Scholars Call Out NYU for Tokenism, Performative Activism and Lack of Communication</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/02/14/nyu-stands-against-hindutva-facism/">Students Protest Hindutva Fascism at Kimmel</a>
						</li>
						<li>
							<a href="https://nyunews.com/2020/03/18/nyus-final-study-abroad-sites-close/">NYU’s Final Study Abroad Sites Close</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2020/09/25/weinstein-occupation-nyu-50-anniversary/">NYU Remembers 50th Anniversary of Weinstein Occupation Today</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/04/15/nyu-students-organize-and-take-action-against-anti-asian-violence/">NYU student groups organize against anti-Asian hate</a>
						</li>
					</ul>
				</li>

				<li>
					<i>Staff Writer, Fall 2019</i>
					<ul style={{listStyleType: "none"}}>
						<li>
							<a href="https://nyunews.com/news/2019/09/17/elizabeth-warren-rally-washington-square-park/">Elizabeth Warren Talks Corruption, Workers’ Rights at Washington Square Park Rally</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2019/09/23/climate-change-strike-foley-square/">‘Change Is Coming’: Thousands March for Climate Action</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2019/10/30/nyu-ai-model-detects-breast-cancer/">Together, AI and Radiologists Could Significantly Improve Breast Cancer Detection</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2019/11/05/science-denial-talk-climate-change/">Big Business Encourages Science-Denial, Panelists Say at Environmental Studies Event</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2019/11/15/latine-students-representation-daca-courtesy-meals/">NYU Lacks Representation, Support for Latine Students, Attendees of Town Hall Say</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2019/11/25/sga-town-hall-jewish-students/">Jewish Religious Holidays, Politicization on Campus Discussed at Town Hall</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2019/11/27/msa-event-marginalized-students/">Shia, Black Students Discuss Challenges of Minority Muslim Status</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	)
}
