const travel = [
	{
		image: "/event/1.png",
		caption: "Tanoura Dance"
	},
	{
		image: "/event/2.png",
		caption: "Expo 2020 Dubai"
	},
	{
		image: "/event/3.png",
		caption: "Expo 2020 Dubai"
	},
	{
		image: "/event/4.png",
		caption: "Fire Sharjah"
	},
	{
		image: "/event/5.png",
		caption: "Global Climate Strike"
	},
	{
		image: "/event/6.png",
		caption: "Global Climate Strike"
	},
	{
		image: "/event/7.png",
		caption: "Belly Dancing"
	},
	{
		image: "/event/8.png",
		caption: "Junteenth Pittsburg"
	},
	{
		image: "/event/9.png",
		caption: "Junteenth Pittsburg"
	},
	{
		image: "/event/10.png",
		caption: "10th Anniversary, NYUAD"
	},
	{
		image: "/event/11.png",
		caption: "Elizabeth Warren Rally, WSP"
	},
	{
		image: "/event/12.png",
		caption: "Elizabeth Warren Rally, WSP"
	},
	{
		image: "/event/13.png",
		caption: "Elizabeth Warren Rally, WSP"
	},
	{
		image: "/event/14.png",
		caption: "Elizabeth Warren Rally, WSP"
	},
	{
		image: "/event/15.png",
		caption: "Elizabeth Warren Rally, WSP"
	},
]

export default travel;