import React from 'react'
import { createUseStyles } from 'react-jss'
import IImage from '../interfaces/Image'

const useStyles = createUseStyles({
	container: {
		margin: 20,
		textAlign: "center"
	},
	image: {
		width: "60%",
		margin: "auto",
		height: "auto",
		border: "2px solid black"
	}
})

export default function ImageContainer({ image } : { image: IImage }) {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<img loading="lazy" className={classes.image} src={image.image} alt={image.caption} />
			<p>{ image.caption }</p>
		</div>
	)
}
