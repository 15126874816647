const travel = [
	{
		image: "/travel/1.png",
		caption: "Take Off"
	},
	{
		image: "/travel/2.png",
		caption: "Landing"
	},
	{
		image: "/travel/3.png",
		caption: "Golden Date Palms, Abu Dhabi, UAE"
	},
	{
		image: "/travel/5.png",
		caption: "Carousel"
	},
	{
		image: "/travel/6.png",
		caption: "Sheikh Zayed Grand Mosque, Abu Dhabi, UAE"
	},
	{
		image: "/travel/7.png",
		caption: "Bangkok Thailand"
	},
	{
		image: "/travel/8.png",
		caption: "Bangkok Thailand"
	},
	{
		image: "/travel/9.png",
		caption: "Mutrah Souq, Oman"
	},
	{
		image: "/travel/10.png",
		caption: "Mutrah Souq, Oman"
	},
	{
		image: "/travel/11.png",
		caption: "Al Fahidi, Dubai, UAE"
	},
	{
		image: "/travel/12.png",
		caption: "Expo 2020, Dubai"
	},
	{
		image: "/travel/13.png",
		caption: "Radio City In The Rain"
	},
	{
		image: "/travel/14.png",
		caption: "Muscat, Oman"
	},
	{
		image: "/travel/15.png",
		caption: "Halekala Volcano, Maui"
	},
]

export default travel;