import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	description: {
	}
})

export default function PhotoVideo() {
	const classes = useStyles()
	return (
		<div>
			<h3>Photography and Videography</h3>
			<p className={classes.description}>Photography and Videography</p>
			<ul style={{listStyleType: "none"}}>
				<li>
					<a href="https://www.instagram.com/travel.with.rosh/?hl=en">Travel With Rosh</a> — Instagram
				</li>
				<li>
					<a href="https://www.instagram.com/p/CbNm1UvORee/">Eternal Prosperity</a> – Photo Installation at NYU’s StudentLink Gallery, Curated by NYU Global Programs
				</li>
				<li>
					Photos on WSN
					<ul style={{listStyleType: "none"}}>
						<li>
							<a href="https://nyunews.com/culture/iequity/2022/03/02/nyu-spring-break-vacation-hawaii-belize-puerto-rico-covid/">Reasons to rethink tropical travel — from the people who live there</a> and <a href="https://nyunews.com/2022/03/05/editors-note-global-events-ukraine-campus-safety-maisie-peters/">Editor’s Note: This week’s top stories, and WSN vs. world news</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/2022/04/29/hawaii-lei-day-may-flowers/">May Day is Lei Day in Hawaiʻi</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/2022/04/22/perfect-picnic-essentials-nature-park/">Ring in spring with the perfect picnic</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/2021/05/04/covid-concerns-after-vaccinations/">Even after vaccination, some students’ concerns about COVID-19 remain</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/07/25/nyu-safety-officer-stabbed/">NYU safety officer in custody after stabbing supervisor at Broome Street dorm</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/abroad/2021/10/14/nyu-florence-homestays/">Homestay closures disconnect students from Florence locals</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/09/08/afghan-student-reflects-on-taliban-takeover/">‘I’m scared for my life’: An Afghan student on the collapse of Afghanistan</a> and <a href="https://nyunews.com/opinion/2022/02/14/nyu-abroad-campuses-no-academic-freedom/">Opinion: NYU must take a stand for academic freedom abroad</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/2021/12/09/confused-december-graduates-nyu-college/">Dec. grads left confused about graduation plans</a>
						</li>
						<li>
							<a href="https://nyunews.com/news/2021/09/27/nyu-abu-dhabi-10-anniversary/">NYU Abu Dhabi celebrates its 10th anniversary</a>
						</li>
						<li>
							<a href="https://nyunews.com/culture/2022/02/07/ranked-quick-stops-kimmel-tisch-hall-stern-nyu-sps/">Your guide to NYU’s Quick Stops</a>
						</li>
					</ul>
				</li>
				<li>
					<a href="https://www.youtube.com/channel/UCjvNvW_5-BGMKUkKgy_ee8w">YouTube</a> — Using Final Cut Pro and Adobe Premiere Pro, I shot and edited 4 vlogs. Two feature my study away at NYU Florence, one features a One Second Every Day for 2019 and one features my high school’s Ocean Bowl team’s trip to the Chesapeake Bay.
				</li>
			</ul>
		</div>
	)
}
