const navLinks = [
	{
		link: "/",
		text: "Home"
	},
	{
		link: "/photography",
		text: "Photography"
	},
	{
		link: "/publications",
		text: "Publications"
	},
	{
		link: "/about",
		text: "About"
	},
]

export default navLinks