const home = [
	{
		image: "/emirates.png",
		caption: "Take Off"
	},
	{
		image: "/grand_mosque.png",
		caption: "Golden Date Palms, Abu Dhabi, UAE"
	},
	{
		image: "/attractions.png",
		caption: "Attractions"
	},
	{
		image: "/grand_mosque_ext.png",
		caption: "Sheikh Zayed Grand Mosque, Abu Dhabi, UAE"
	},
	{
		image: "/thailand.png",
		caption: "Bangkok, Thailand"
	},
	{
		image: "/souq_oman.png",
		caption: "Mutrah Souq, Oman"
	},
	{
		image: "/expo.png",
		caption: "Expo 2020 Dubai"
	},
	{
		image: "/radio_city.png",
		caption: "Radio City In The Rain"
	},
	{
		image: "/muscat_oman.png",
		caption: "Muscat, Oman"
	},
	{
		image: "/maui.png",
		caption: "Haleakalā Volcano, Maui"
	},

]

export default home;