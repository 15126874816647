import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'

import Nav from '../components/Nav'
import MobileNav from '../components/MobileNav'
import Footer from '../components/Footer'

const useStyles = createUseStyles({
	container: {
		padding: 30,
		display: "flex",
		flexDirection: "row"
	},
	pageContent: {
		paddingLeft: 30,
		flex: 1,
		// overflowY: "auto"
	},
	left: {
		position: "sticky",
		top: 0,
		left: 0,
		height: "93vh",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},
	mobileContainer: {
		padding: 30,
	},
})

export default function Layout({page}: {page: JSX.Element}) {
	const classes = useStyles()
	
	const [width] = useState(Number(window.innerWidth))
	console.log(window.innerWidth)
	return (
		<>
			{
				width > 700 ? <div className={classes.container}>	
				<div className={classes.left}>
					<Nav />
					{/* <Footer /> */}
				</div>
				<div className={classes.pageContent}>
					<div>
						{ page }
					</div>
				</div>
			</div> : <div>
					<MobileNav />
				<div className={classes.mobileContainer}>
					{page}
				</div>
			</div>
			}
			
		</>
	)
}
