import React, {useState} from 'react'
import { createUseStyles } from 'react-jss'
import navLinks from '../content/navLinks'

const useStyles = createUseStyles({
	container: {
		padding: 30,
		backgroundColor: "#c5c6d0",
		position: "sticky",
		top: 0,
		left: 0,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	name: {
		transform: "translateY(5px)"
	},
	arrow: {
		width: 30,
	},
	arrowClose: {
		animation: "$arrowClose 250ms forwards"
	},
	"@keyframes arrowClose": {
		"0%": {
			transform: "rotateZ(180deg)"
		},
		"100%": {
			transform: "rotateZ(0)"
		}
	},
	arrowOpen: {
		animation: "$arrowOpen 250ms forwards"
	},
	"@keyframes arrowOpen": {
		"0%": {
			transform: "rotateZ(0)"
		},
		"100%": {
			transform: "rotateZ(180deg)"
		}
	},
	menu: {
		display: "block",
		padding: 0,
		backgroundColor: "#c5c6d0",
		borderTop: "1px solid #414a4c",
		height: 0,
		animation: "$initial 1ms forwards"
	},
	"@keyframes initial": {
		"0%": {
			color: "white",
			border: "none" 
		},
		"100%": {
			color: "white",
			border: "none"
		}
	},
	menuOpen: {
		display: "block",
		animation: "$menuOpen 250ms forwards"
	},
	"@keyframes menuOpen": {
		"0%": {
			display: "block"
		},
		"100%": {
			display: "block",
			padding: 15,
			height: 18
		}
	},
	menuClosed: {
		animation: "$menuClosed 250ms forwards",
	},
	"@keyframes menuClosed": {
		"0%": {
			padding: 15,
			height: 18,
		},
		"100%": {
			padding: 0,
			height: 0,
			color: "white",
			border: "none"
		}
	}

})

export default function MobileNav() {
	const classes = useStyles()
	const [arrowOpen, setArrowOpen] = useState(false)
	const [menuOpen, setMenuOpen] = useState(false)
	const [firstOpen, setFirstOpen] = useState(false)

	const toggleArrow = () => {
		setArrowOpen(!arrowOpen)
		setMenuOpen(!menuOpen)
	}

	const applyClasses = () => {
		if (arrowOpen && !firstOpen) {
			return `${classes.arrow} ${classes.arrowClose}`
		}

		if (!arrowOpen && firstOpen) {
			return `${classes.arrow} ${classes.arrowClose}`
		}

		if (arrowOpen && firstOpen) {
			return `${classes.arrow} ${classes.arrowOpen}`
		}

		return `${classes.arrow}`
	}

	const applyMenuClasses = () => {
		if (menuOpen && !firstOpen) {
			return classes.menu
		}
		if (!menuOpen && firstOpen) {
			return `${classes.menu} ${classes.menuClosed}`
		}

		if (menuOpen && firstOpen) {
			return `${classes.menu} ${classes.menuOpen}`
		}

		return classes.menu
	}
	
	

	return (
		<div>
		<div className={classes.container}>
			<span className={classes.name}>
				<b>
					Roshni Raj
				</b>
			</span>
			<img 
				onClick={() => {
					toggleArrow()
					setFirstOpen(true)
				}}
				className={applyClasses()}
				src="/downArrow.png"
				alt=""
			/>
		</div>
			{
				navLinks.map(navItem => {
					return <a className={applyMenuClasses()} href={navItem.link}>
						{navItem.text}
					</a>
				})
			}
		</div>
	)
}
