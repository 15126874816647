import React from 'react';
import {createUseStyles } from "react-jss"
import Images from '../components/Images';
import home from '../content/home';

const useStyles = createUseStyles({
	container: {
		// height: "90vh"
	}
})

function Home() {
	const classes = useStyles()
  return (
    <div className={classes.container}>
		<Images images={home} />
    </div>
  );
}

export default Home;