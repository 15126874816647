import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

import navLinks from '../content/navLinks'

const useStyles = createUseStyles({
	nav: {
		position: "sticky",
		top: 0,
		left: 0,
	},
	linkContainer: {
		paddingBottom: 5,
		width: 300 
	},
	link: {
		textDecoration: "none",
		color: "black",
		"&:hover": {
			textDecoration: "underline"
		},
		"&:link": {
			color: "black"
		},
		"&:visited": {
			color: "black"
		}
	},
})

export default function Nav() {
	const classes = useStyles()

	return (
		<div className={classes.nav}>
			<h1>Roshni Raj</h1>
			<div>
				{
					navLinks.map(navLink => {
						return <div className={classes.linkContainer}>
							<Link key={navLink.text} className={classes.link} to={navLink.link}>{ navLink.text }</Link>
						</div>
					})
				}
			</div>
		</div>
	)
}
