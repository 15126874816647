import React from 'react';
import { createUseStyles } from 'react-jss';

import ImageContainer from '../components/ImageContainer';

import TravelImages from "../content/travel"
import EventImages from "../content/event"

const useStyles = createUseStyles({
	headers: {
		textAlign: "center"
	}
})

export default function Photography() {
	const classes = useStyles()
	return (
		<div>
			<h1 className={classes.headers}>Travel</h1>
			{
				TravelImages.map(image => {
					return <ImageContainer image={image}/>
				})
			}

			<h1  className={classes.headers}>Event</h1>
			{
				EventImages.map(image => {
					return <ImageContainer image={image}/>
				})
			}
		</div>
	)
}
