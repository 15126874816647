import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	description: {
		maxWidth: 700
	}
})

export default function Polygiea() {
	const classes = useStyles()
	return (
		<div>
			<h3>Polygeia: Global Health Think Tank</h3>
			<p className={classes.description}>After moving back home when the COVID-19 pandemic started, I saw how vital it is for science, health and policy to work together in times of uncertainty and deepening socioeconomic disparities. Researching and writing these reports came out of my desire to contribute and make a conscious impact on public discourse.</p>
			<ul style={{listStyleType: "none"}}>
				<li>
					<a href="https://docs.google.com/document/d/1GcpkccqguiyESGp_Zcw2h28EY0kLWnLfxbH3nzMGl5g/edit?usp=sharing">Mental Health Care Interventions Ensuring Child and Adolescent Psychosocial Health and Wellbeing in War and Conflict Zones Using Multisectoral Collaboration</a> — Oral Presentation Keynote at the 2021 Polygeia Conference
				</li>
				<li>
					<a href="https://docs.google.com/document/d/1Z5nE2MWyvleFDUdgrzw-m_GR0Wf1bUdTyIup9mzIrBA/edit?usp=sharing">The Effectiveness of Wearing Masks against Respiratory Virus Transmission with a Focus on COVID-19</a> — Poster Presentation at the 2020 Polygeia Conference
				</li>
			</ul>
		</div>
	)
}
