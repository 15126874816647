import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	container: {
		height: "90vh",
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	img: {
		// maxWidth: 300,
		width: "30%"
	},
	description: {
		width: 400,
		marginLeft:30
	}
})

export default function About() {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<img className={classes.img} src="/profile.jpg" alt="Roshni Raj profile" />
			<div className={classes.description}>
				<h1>Roshni Raj</h1>
				Roshni Raj is a rising senior double majoring in Neural science and Anthropology with a minor in Chemistry at NYU. Reading the morning newspaper as a child with her grandfather and observing cultural health practices around the world sparked her desire to tell stories. As a pre-medical student and ever-learning journalist, she aspires to work as a doctor and a journalist who focuses on the intersections of public health, social justice, travel and culture. Her dream is to become the next Dr. Sanjay Gupta, Dr. Celine Gounder or Dr. Alok Patel. She enjoys meeting new people, late-night food runs with friends, photography, travel shows, language learning and the emdash. Follow her <a target="_blank" href="https://www.instagram.com/roshnir297/">@roshnir297</a> on Instagram and Twitter, and keep up with her photography <a target="_blank" href="https://www.instagram.com/travel.with.rosh/">@travel.with.rosh</a> on Instagram.
				
			</div>
			
			
		</div>
	)
}
