import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	description: {
	}
})

export default function NSLI() {
	const classes = useStyles()
	return (
		<div>
			<h3>National Security Language Initiative for Youth </h3>
			<p className={classes.description}>I studied overseas through the merit-based scholarship in the NSLI-Y Summer Hindi 2016 Program sponsored by the U.S. Department of State, sparking my interest in critical foreign languages and cultures.</p>
			<ul style={{listStyleType: "none"}}>
				<li>
				Breaking My Cultural Iceberg: India’s Diversity — my first published article on <a href="https://nsliy-interactive.org/2018/08/23/breaking-my-cultural-iceberg-indias-diversity">NSLI-Y Interactive</a> that became a finalist on <a href="https://myfamilytravels.com/students/67598-breaking-my-cultural-iceberg-indias-diversity">My Family Travels</a>
				</li>
				<li>
					<a href="https://artofjourney.wixsite.com/explore">Art Of Journey</a> — a blog I created to document my travels and reflect on my NSLI-Y Hindi Summer 2016 exchange program sponsored by the U.S. Department of State.
				</li>
				<li>
					<a href="https://artofjourney.wixsite.com/explore/single-post/2017/11/21/ing-lishh">Ing-Lishh</a> — a creative piece illuminating societal stigmas and difficult interactions immigrants and non-native English speakers face in America
				</li>
				<li>
					<a href="https://artofjourney.wixsite.com/explore/single-post/2018/10/09/5-fun-ways-to-continue-learning-a-language">5 Fun Ways to Continue Learning a Language</a>
				</li>
			</ul>
		</div>
	)
}
