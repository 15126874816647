import React from 'react'
import { createUseStyles } from 'react-jss'
import IImage from '../interfaces/Image'
import ImageContainer from './ImageContainer'

const useStyles = createUseStyles({
	container: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		textAlign: "center"
	}
})

export default function Images({ images } : { images: Array<IImage> }) {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			{
				images.map(image => {
					return <ImageContainer image={image} />
				})
			}
		</div>
	)
}
