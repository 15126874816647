import React from 'react'
import { createUseStyles } from 'react-jss'

import Journal from '../components/Works/Journal'
import WashingtonSquareNews from '../components/Works/WashingtonSquareNews'
import Polygiea from '../components/Works/Polygiea'
import NSLI from '../components/Works/NSLI'
import PhotoVideo from '../components/Works/PhotoVideo'

const useStyles = createUseStyles({
	container: {
		// height: "90vh",
		maxWidth: 900
	}
})

export default function Event() {
	const classes = useStyles()
	return (
		<div className={classes.container}>
			<h1>Publications</h1>
			<Journal />	
			<WashingtonSquareNews />
			<Polygiea />
			<NSLI />
			<PhotoVideo />
		</div>
	)
}
