import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	description: {
	}
})

export default function Journal() {
	const classes = useStyles()
	return (
		<div>
			<h3>Frontiers in Behavioral Neuroscience</h3>
			<p className={classes.description}>My first published research paper in a peer-reviewed scientific journal. I made figures using BioRender and helped edit multiple written drafts. As an undergraduate research assistant in the Sullivan Lab at NYU Langone, I perform brain image analysis using NIH software ImageJ, collect behavioral data collection with BORIS, participate in weekly lab meeting and presented at the Eastern Psychological Association Conference.</p>
			<ul style={{listStyleType: "none"}}>
				<li>
					<a href="https://doi.org/10.3389/fnbeh.2022.806323">Neurobiology of Parental Regulation of the Infant and Its Disruption by Trauma Within Attachment </a>
				</li>
			</ul>
		</div>
	)
}
